@import '../../styles/colors.scss';
@import '../../styles/variables.scss';



.nav{
    @media (min-width: $smallscreen-bp){
        justify-content: space-between !important;
    }
    &-item{
        color: $white;
        @media only screen and (min-width: $smallscreen-bp){
            padding-top: 16px;
        }
        font-size: 0.9em;
    }
    &-link{
        &.active{
            font-weight: bold;
        }
        padding-bottom:0;
        padding-left:0;
        padding-right:0;
    }
    &-logo{
        &-container{
            width: 250px;
            background-color: $black;
            padding:25px !important;
        }
    }
    &-btn{
        border-radius: 20px !important;
        line-height: 1.4em;            
    }
    &-container{
        align-items: flex-start !important;
        justify-content: space-between;
        width: 100%;
    }
}
.miniNav{
    &-container{
        background-color: $black;
        width: 100%;
        z-index:5;
    }
}
#fullSizeNav{
    display:none;
}
#miniNav{
    display:flex;
}
@media (min-width: 992px){
    #fullSizeNav{
        display:flex;
    }
    #miniNav{
        display: none;
    }
}