@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700;800;900&display=swap');

//Variables
$body-bg: $black;
$body-color: $white;
$primary: $skyBlue;
$spacer: 1em;

$font-family-sans-serif: 'Montserrat', sans-serif !default;
$font-family-serif:      'Montserrat', sans-serif !default;
$font-family-monospace:  'Montserrat', sans-serif !default;
$font-family-base:       $font-family-sans-serif !default;

$phone-bp: 576px;
$tablet-bp: 768px;
$smallscreen-bp: 992px;
$largescreen-bp: 1500px;