@import '../../styles/colors.scss';
@import '../../styles/variables.scss';
.services{
    display: flex;
    color: $black;
    flex-wrap: wrap;
    @media only screen and (min-width: $tablet-bp){                    
        flex-wrap: nowrap;
    }
    &.reverse{        
        flex-wrap: wrap-reverse;
    }
    &-img{
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
    } 
    &-container{
        flex-wrap: wrap;
    }
    &-overlay{
        margin-top: 18em;
        &-welcomeText{
            color: $black;
            font-size: 1.9em;
            line-height: 1.6em;
            font-weight: 700;
            @media only screen and (min-width: $tablet-bp){
                font-size: 2.2em;
            }
        }
    }
    &.top{
        margin-top: 5em;
        @media only screen and (min-width: $tablet-bp){
            margin-top: 10em;
        }
        @media only screen and (min-width: $largescreen-bp){
            margin-top: 12em;
        }
    }
    &-body{
        font-weight: 300;
        font-size: 0.9em;
        line-height: 1.6em;
        vertical-align: top;
    }
    &-panel{
        font-size: 0.9em;
        color: $black;
        &-container{
            display: block;
            flex-wrap: wrap;
            @media only screen and (min-width: $tablet-bp){                    
                flex-wrap: nowrap;
            }
        }
    }     
    
}
.services-bg1 {
    background-image: url(../../assets/home-bg.jpg); 
    background-repeat: no-repeat;
    min-height: 45em;
    background-size: cover;
    @media only screen and (min-width: $smallscreen-bp){
        background-size: contain;
        min-height: 67vw;
    }
    @media only screen and (min-width: $largescreen-bp){
        background-size: cover;
        min-height: 67em;
    }
    margin-top: 68px;  
}
.services-bg3{
    background-image: -webkit-linear-gradient(273deg, rgba(0,0,0,0) 2%, #FFFFFF 2.3%);
    top: -5em;
    position:relative;
    @media only screen and (min-width: $tablet-bp){
        background-image: -webkit-linear-gradient(273deg, rgba(0,0,0,0) 8%, #FFFFFF 8.3%);
        top: -8em;
    }
    @media only screen and (min-width: $largescreen-bp){
        background-image: -webkit-linear-gradient(273deg, rgba(0,0,0,0) 11%, #FFFFFF 11.3%);
        top: -9em;
    }
}