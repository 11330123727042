@import 'colors';
@import 'variables';
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/font-awesome/scss/font-awesome";

body,html{
  height: 100%;
}
body{    
  margin: 0;
  padding:0;
  font-family: $font-family-base;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
.header-row{
  position: absolute;
  top:0px;
  width:100%;
}
.content-row{
  width:100%;
}
.content-bg{
  background-color: #ffffff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.toast{
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 10;
  background-color: rgba(144,238,144, 0.85);
}