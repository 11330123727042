@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.footer{
    &-container{
        font-size: 0.8em;
    }
    &-bg{
        background-color: $black;
        position: relative;
    }
    &-btn{
        border-radius: 20px !important;
    }
    &-links{        
        text-align: center;
        @media only screen and (min-width: $phone-bp){
            text-align: left;
        }
    }
}