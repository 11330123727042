@import '../../styles/colors.scss';
@import '../../styles/variables.scss';
.testimonials{  
    display: flex;
    color: $black;
    &.top{
        margin-top: 7 * $spacer;
    }
    &-subtitle{        
        font-weight:700; 
    }
    &-emphasis{       
        color: $skyBlue; 
        font-weight:700; 
        font-size: 1.2em;
    }
    &-body{
        font-weight: 300;
        font-size: 0.9em;
        line-height: 1.6em;
        vertical-align: top;
        background-color: $ltGrey;
        
    }
    &-img{
        max-height: 50px;

        &bg{                             
            padding: 2em;
            display:inline-block;
        }
    }   
    &-firstpara{
        &:before{
            display: flex;
            content: "\201C";
            font-size: 7em;
            position: absolute;
            left: 5px;
            top: 35px;
            color:$skyBlue;
        }
    }

    &-overlay{
        margin-top: 18em;
        &-welcomeText{
            color: $black;
            font-size: 1.9em;
            line-height: 1.6em;
            font-weight: 700;
            @media only screen and (min-width: $tablet-bp){
                font-size: 2.2em;
            }
        }
    }  
    
}
.testimonials-bg1 {
    background-image: url(../../assets/home-bg.jpg); 
    background-repeat: no-repeat;
    min-height: 45em;
    background-size: cover;
    @media only screen and (min-width: $smallscreen-bp){
        background-size: contain;
        min-height: 67vw;
    }
    @media only screen and (min-width: $largescreen-bp){
        background-size: cover;
        min-height: 67em;
    }
    margin-top: 68px; 
}
.testimonials-bg3{
    background-image: -webkit-linear-gradient(273deg, rgba(0,0,0,0) 3%, #FFFFFF 3.3%);
    top: -5em;
    position:relative;
    @media only screen and (min-width: $tablet-bp){
        background-image: -webkit-linear-gradient(273deg, rgba(0,0,0,0) 7%, #FFFFFF 7.3%);
        top: -8em;
    }
    @media only screen and (min-width: $largescreen-bp){
        background-image: -webkit-linear-gradient(273deg, rgba(0,0,0,0) 10%, #FFFFFF 10.3%);
        top: -9em;
    }
}
