@import '../../styles/colors.scss';
@import '../../styles/variables.scss';
.home{
    &-overlay{
        margin-top: 10em;
        &-siteName{
            color: $skyBlue;
            font-weight: 700;
            font-size: 2.5em;
            line-height: 1em;            
            margin-top: 20px;
            @media only screen and (min-width: $smallscreen-bp){
                font-size: 4.3vw;
            }
            @media only screen and (min-width: $largescreen-bp){
                font-size: 4em;
            }
        }
        &-welcomeText{
            color: $black;
            font-weight: 300;
            font-size: 1em;
            line-height: 1.6em;
        }
    }
    &-about{
        display: flex;  
        color: $black;      
        &.top{
            margin-top: 5em;
            @media only screen and (min-width: $tablet-bp){
                margin-top: 10em;
            }
            @media only screen and (min-width: $largescreen-bp){
                margin-top: 12em;
            }
        }
        &-body{
            font-weight: 300;
            font-size: 0.9em;
            line-height: 1.6em;
            vertical-align: top;
        }
        &-btn{
            border-radius: 25px;
            padding-left: 2em;
            padding-right: 2em;
        }        
    }
    &-services{
        display: flex;
        &.top{
            margin-top: 10 * $spacer;
        }
        &-body{
            font-weight: 300;
            font-size: 0.9em;
            line-height: 1.6em;
            vertical-align: top;
        }
        &-panel{
            background-color: $white;
            font-size: 0.9em;
            color: $black;
            &-container{
                display: flex;
                // @media only screen and (min-width: $tablet-bp){                    
                //     flex-wrap: nowrap;
                //     display: flex;
                // }
            }
        }      
    }
    &-featuredskills{
        display: flex;
        color: $black;     
        &.top{
            margin-top: 10 * $spacer;
        }
        &-subtitle{        
            font-weight:900; 
        }
        &-emphasis{       
            color: $skyBlue; 
            font-weight:900; 
            font-size: 1.2em;
        }
        &-body{
            font-weight: 300;
            font-size: 0.9em;
            line-height: 1.6em;
            vertical-align: top;
        }
        &-panel{
            background-color: $white;
            font-size: 0.9em;
            color: $black;
            &-container{
                display: flex;
                border: 1px solid $ltrGrey;
                box-shadow: 0px 0px 25px 25px $ltrGrey;
                flex-wrap: wrap;
            }
        }
        &-img{
            max-height: 50px;

            &bg{                
                background-color: $ltGrey;                
                padding: 1em;
                display:inline-block;
            }
        }        
    }
    &-testimonials{
        display: flex;
        color: $white;     
        &.top{
            margin-top: 10 * $spacer;
        }
        &-emphasis{       
            font-size: 1.2em;
        }
        &-subtitle{       
            font-weight:900; 
        }
        &-body{
            font-weight: 300;
            font-size: 0.9em;
            line-height: 1.6em;
            vertical-align: top;
            background-color: $skyBlue;
            height: 30em;
            display: flex;
            & div{
                & h2{
                    text-align: right;
                }
                & h3{
                    text-align: right;
                }
            }
        }
        &-carousel{
            background-color: $skyBlue;
            display: flex;
        }   
    }
    &-contact{
        color: $black;
        
        &.top{
            margin-top: 10 * $spacer;
        }
        &-img{
            &bg{                
                background-color: $ltGrey;                
                padding: 1em;
                display:inline-block;
            }
            @media only screen and (min-width: $tablet-bp){
                padding-top:0;
            }
            padding-bottom: 2em;
            padding-top:2em;
        }
    }
}
.contact{
    display: flex;

    &-subtitle{       
        font-weight:900; 
    }
    &-body{
        font-weight: 300;
        font-size: 0.9em;
        line-height: 1.6em;
        vertical-align: top;
        & .input-group-text{
            font-size: 1.6em;
            color: #7f7f7f;
        }  
        & .input-group-prepend{
            width:3em;
        }
    } 
    &-form{
        display: flex;
        flex-wrap: nowrap;
    }
    &-btn{
        width:100%;
        margin-bottom: 2em;
    }  
}
.bg1 {
  background-image: url(../../assets/home-bg.jpg); 
  background-repeat: no-repeat;
  min-height: 45em;
  background-size: cover;
  @media only screen and (min-width: $smallscreen-bp){
      background-size: contain;
      min-height: 67vw;
  }
  @media only screen and (min-width: $largescreen-bp){
      background-size: cover;
      min-height: 67em;
  }
  margin-top: 68px;  
}
.bg2{
    background-image: -webkit-linear-gradient(273deg, rgba(0,0,0,0) 6%, #FFFFFF 6.3%);
    top: -5em;
    position:relative;
    @media only screen and (min-width: $tablet-bp){
        background-image: -webkit-linear-gradient(273deg, rgba(0,0,0,0) 11%, #FFFFFF 11.3%);
        top: -6em;
    }
    @media only screen and (min-width: $largescreen-bp){
        background-image: -webkit-linear-gradient(273deg, rgba(0,0,0,0) 19%, #FFFFFF 19.3%);
        top: -9em;
    }
}
.bg3{
    background-color: $white;
    background-image: -webkit-linear-gradient(273deg, #FFFFFF 5%, #000000 5.3%);    
    @media only screen and (min-width: $tablet-bp){
        background-image: -webkit-linear-gradient(273deg, #FFFFFF 8%, #000000 8.3%);    
    }
    @media only screen and (min-width: $smallscreen-bp){
        background-image: -webkit-linear-gradient(273deg, #FFFFFF 10%, #000000 10.3%);
    }
    @media only screen and (min-width: $largescreen-bp){
        background-image: -webkit-linear-gradient(273deg, #FFFFFF 14%, #000000 14.3%);         
    }
}
.bg4{
    background-color: $black;
    background-image: -webkit-linear-gradient(273deg, #000000 6%, #FFFFFF 6.3%);    
    @media only screen and (min-width: $tablet-bp){
        background-image: -webkit-linear-gradient(273deg, #000000 9%, #FFFFFF 9.3%);    
    }
    @media only screen and (min-width: $smallscreen-bp){
        background-image: -webkit-linear-gradient(273deg, #000000 11%, #FFFFFF 11.3%); 
    }
    @media only screen and (min-width: $largescreen-bp){
        background-image: -webkit-linear-gradient(273deg, #000000 15%, #FFFFFF 15.3%);         
    }
}
.bg5{
    background-color: $skyBlue;
    background-image: -webkit-linear-gradient(273deg, #FFFFFF 6%, $skyBlue 6.3%);    
    @media only screen and (min-width: $tablet-bp){
        background-image: -webkit-linear-gradient(273deg, #FFFFFF 9%, $skyBlue 9.3%);    
    }
    @media only screen and (min-width: $smallscreen-bp){
        background-image: -webkit-linear-gradient(273deg, #FFFFFF 11%, $skyBlue 11.3%); 
    }
    @media only screen and (min-width: $largescreen-bp){
        background-image: -webkit-linear-gradient(273deg, #FFFFFF 15%, $skyBlue 15.3%);         
    }
}
.bg6{
    background-color: $skyBlue;
    background-image: -webkit-linear-gradient(273deg, $skyBlue 6%, $ltrGrey 6.3%);    
    @media only screen and (min-width: $tablet-bp){
        background-image: -webkit-linear-gradient(273deg, $skyBlue 9%, $ltrGrey 9.3%);    
    }
    @media only screen and (min-width: $smallscreen-bp){
        background-image: -webkit-linear-gradient(273deg, $skyBlue 11%, $ltrGrey 11.3%); 
    }
    @media only screen and (min-width: $largescreen-bp){
        background-image: -webkit-linear-gradient(273deg, $skyBlue 15%, $ltrGrey 15.3%);         
    }
}
